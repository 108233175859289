import { formatDate } from 'its-js-utility';
import { array, object } from 'prop-types';
import React from 'react';
import SimpleBar from 'simplebar-react';
import { v4 as uuid } from 'uuid';
import useDialog from '../../hooks/useDialog';
import { useIsMobile } from '../../hooks/useIsMobile';
import { formatTime } from '../../lib';
import Button from '../Shared/Button';
import ItsDialog, { DialogHeader } from '../Shared/ItsDialog';
import styles from './ViewAuditLog.module.scss';

export const ViewAuditLog = ({ logs, sections = [] }) => {
  const { isShowing, show, hide } = useDialog();
  const isMobile = useIsMobile();

  return (
    <>
      <Button data-test-id="gRBzJDZP7f45hYfjFjhNK" onClick={show} typeStyle="link">
        Open log
      </Button>

      <ItsDialog isOpen={isShowing} onDismiss={hide} aria-label="Audit logs of the document">
        <DialogHeader title="Log" close={hide} />

        <SimpleBar style={{ maxHeight: isMobile ? '348px' : '580px' }}>
          <table className={styles.logTable}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => (
                <tr key={uuid()}>
                  <td>{log.user}</td>
                  <td>{formatDate(new Date(log.timeStamp))}</td>
                  <td>{formatTime(new Date(log.timeStamp))}</td>
                  <td>{log.action === 'Update' ? <Changes changes={log.changes} sections={sections} /> : log.action}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </SimpleBar>

        <div className={styles.actions}>
          <Button data-test-id="JXsYXMEgNX0vRxdnFCm5x" onClick={hide} typeStyle="light">
            Close
          </Button>
        </div>
      </ItsDialog>
    </>
  );
};

ViewAuditLog.propTypes = {
  logs: array.isRequired,
  sections: array,
};

const SupportedChangeType = Object.freeze({
  Notes: 'Notes',
  DocumentType: 'Taxonomy.DocumentType',
  Keyowrds: 'Taxonomy.DocumentTags',
  Pii: 'PiiSection',
});

const Changes = ({ changes, sections }) => {
  const relevantChanges = changes.filter((change) => Object.values(SupportedChangeType).includes(change.name));

  return (
    <ul className={styles.changes}>
      {relevantChanges.map((change) => (
        <li key={uuid()}>
          <Change change={change} sections={sections} />
        </li>
      ))}
    </ul>
  );
};

Changes.propTypes = {
  changes: array.isRequired,
  sections: array.isRequired,
};

const Change = ({ change, sections }) => {
  switch (change.name) {
    case 'Notes':
      return (
        <>
          Update <i>Notes</i> from <span>{change.previousValue || 'none'}</span> to <span>{change.newValue || 'none'}</span>.
        </>
      );

    case 'Taxonomy.DocumentType':
      return (
        <>
          Update <i>Document type</i> from <span>{change.previousValue}</span> to <span>{change.newValue}</span>.
        </>
      );

    case 'Taxonomy.DocumentTags':
      return (
        <>
          Update <i>Keywords</i> from <span>{change.previousValue.join(', ') || 'none'}</span> to <span>{change.newValue.join(', ') || 'none'}</span>.
        </>
      );

    case 'PiiSection': {
      const previousSection = sections.find((x) => x.mdmExternalId === change.previousValue);
      const newSection = sections.find((x) => x.mdmExternalId === change.newValue);
      return (
        <>
          Update <i>PII section</i> from <span>{(previousSection && previousSection.code) || 'none'}</span> to{' '}
          <span>{(newSection && newSection.code) || 'none'}</span>.
        </>
      );
    }

    default:
      throw new Error(`No implemenation found for ${change.name}. Add it to the switch case to support it.`);
  }
};

Change.propTypes = {
  change: object.isRequired,
  sections: array.isRequired,
};
