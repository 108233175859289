import { RemoteData } from 'its-js-utility';
import { createReducer } from '../../lib';
import * as actionTypes from './constants';

const initialState = {
  name: '',
  email: '',
  roles: [],
  remoteDataState: RemoteData.NotAsked,
};

const userReducer = createReducer(initialState)({
  [actionTypes.SET_USER]: (state, { payload: { name, email } }) => ({
    ...state,
    name,
    email,
  }),
  [actionTypes.FETCH_USER_ROLES]: (state, _) => ({
    ...state,
    remoteDataState: RemoteData.Loading,
  }),
  [actionTypes.FETCH_USER_ROLES_SUCCESS]: (state, { payload: roles }) => ({
    ...state,
    roles,
    remoteDataState: RemoteData.Success(roles),
  }),
  [actionTypes.FETCH_USER_ROLES_FAILURE]: (state, { payload: error }) => ({
    ...state,
    remoteDataState: RemoteData.Failure(error),
  }),
});

export default userReducer;
