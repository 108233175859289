import { mdiAccountAlert, mdiLockOutline, mdiPaperclip } from '@mdi/js';
import MdiIcon, { Icon } from '@mdi/react';
import '@reach/checkbox/styles.css';
import cn from 'classnames';
import { isEmpty } from 'ramda';
import { mapTaxonomyDisplayName } from 'its-js-utility';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useRovingTabIndex, useFocusEffect } from 'react-roving-tabindex';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'throttle-debounce';
import useResizeObserver from '../../hooks/useResizeObserver';
import { getFormatedDate, getFormatedTime, isEmailDocument } from '../../lib/helpers';
import * as constants from '../constVariables';
import EmailDirectionIndicator from '../SearchResult/EmailDirectionIndicator';
import { IndeterminateCheckbox, Tag } from '../Shared';
import FlexibleKeywordsContent from '../Shared/flexibleContent/FlexibleKeywordsContent';
import styles from './CaseDocumentTable.module.scss';

export const directionColumn = {
  Header: '',
  accessor: 'direction',
  disableSortBy: true,
  width: 32,
  Cell: (cellInfo) => {
    const {
      cell: { value: direction },
    } = cellInfo;
    return (
      <div className={styles.directionWrapper}>
        <div>
          {direction && (
            <EmailDirectionIndicator
              direction={direction}
              className={cn(styles.emailIndicator, {
                [styles.internalDirection]: direction === constants.emailDirection.internal,
              })}
            />
          )}
        </div>

        <div>
          <MdiIcon path={mdiLockOutline} size="16px" color={constants.GREY_ICON_COLOR} />
        </div>
      </div>
    );
  },
};

export const fromColumn = {
  Header: 'From',
  accessor: 'from',
  className: styles.from,
  classNameHeader: styles.from,
  Cell: (cellInfo) => <CellClamp className="bold">{cellInfo.cell.value}</CellClamp>,
};

export const toColumn = {
  Header: 'To',
  accessor: 'to',
  className: styles.to,
  classNameHeader: styles.to,
  Cell: (cellInfo) => <CellClamp className="bold">{cellInfo.cell.value}</CellClamp>,
};

export const dateColumn = {
  Header: 'Date',
  accessor: 'date',
  width: 84,
  Cell: (cellInfo) => {
    const dateTime = cellInfo.cell.value;
    return (
      <div>
        <div>{getFormatedDate(dateTime)}</div>
        <div>{getFormatedTime(dateTime)}</div>
      </div>
    );
  },
};

export const notesColumn = {
  Header: 'Notes',
  accessor: 'notes',
  className: styles.notes,
  classNameHeader: styles.notes,
  width: 250,
  Cell: (cellInfo) => <CellClamp>{cellInfo.cell.value}</CellClamp>,
};

export const titleColumn = {
  Header: 'Title',
  accessor: 'title',
  className: styles.title,
  classNameHeader: styles.title,
  width: 250,
  Cell: (cellInfo) => <CellClamp>{cellInfo.cell.value}</CellClamp>,
};

export const docTypeColumn = {
  Header: 'Doc.Type',
  accessor: 'docType',
  Cell: (cellInfo) => {
    const {
      cell: { value: docType },
    } = cellInfo;
    return docType ? (
      <div className={cn(styles.centerVerticalUtil, styles.breakWordUtil)}>
        <Tag text={mapTaxonomyDisplayName(docType)} />
      </div>
    ) : null;
  },
};

const rebuildTooltip = debounce(200, false, ReactTooltip.rebuild);

export const keywordsColumn = {
  Header: 'Keywords',
  accessor: 'keywords',
  className: styles.keywords,
  classNameHeader: styles.keywords,
  disableSortBy: true,
  Cell: (cellInfo) => {
    const ref = useRef(null);

    // Need to explicit rebuild tooltip on size change
    // due to how ReactTooltip works and how FlexibleContent hides content
    useResizeObserver(ref, rebuildTooltip);

    const {
      cell: { value: keywords },
    } = cellInfo;
    if (!keywords || keywords.length === 0) return null;

    return (
      <div ref={ref} className={styles.centerVerticalUtil}>
        <FlexibleKeywordsContent keywords={keywords} />
      </div>
    );
  },
};

export const additionalInfoColumn = {
  Header: '',
  accessor: 'additionalInfo',
  disableSortBy: true,
  width: 64,
  Cell: (cellInfo) => {
    const {
      cell: { value },
    } = cellInfo;
    const { hasAttachments, containsPii } = value;
    return (
      <div className={cn(styles.centerVerticalUtil, styles.additionalInfo)}>
        {containsPii && <Icon path={mdiAccountAlert} title="Includes personally identifiable information" size={constants.SMALL_ICON_SIZE} />}
        {hasAttachments && <Icon path={mdiPaperclip} title="Has attachments" size={constants.SMALL_ICON_SIZE} />}
      </div>
    );
  },
};

export const addSelectionColumn = (hooks, toggleSelection) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'selection',
      disableSortBy: true,
      width: 40,
      Cell: (cellInfo) => {
        const {
          row: { id, getToggleRowSelectedProps, original },
        } = cellInfo;
        const documentId = id;
        const toggleRowSelectedProps = getToggleRowSelectedProps();
        const onChange = (event) => {
          toggleRowSelectedProps.onChange(event);
          toggleSelection(documentId);
        };
        return (
          <div className={styles.centerVerticalUtil}>
            <IndeterminateCheckbox {...toggleRowSelectedProps} onChange={onChange} disabled={original.hasNoFiles} />
          </div>
        );
      },
    },
    ...columns,
  ]);
};

// eslint-disable-next-line react/prop-types
export const CellClamp = ({ children, className }) => <div className={cn(styles.truncateOverflow, className)}>{children}</div>;

export const mapDocumentToViewModel = (document) => {
  const { id, direction, date, taxonomy, files, sender, user, recipients, otherRecipients, notes, subject, piiSection } = document;
  const docType = (taxonomy && taxonomy.documentType) || '';
  const keywords = (taxonomy && taxonomy.documentTags) || [];
  const from = (sender && sender.name) || user;
  const hasNoFiles = keywords.includes('DocFlow');
  const isEmail = isEmailDocument(document);

  const title = isEmail ? subject : files.length > 0 && files[0].name;

  let to = '';
  if (isEmail && recipients && recipients.length > 0) {
    to = recipients[0].name;

    const additionalRecipients = recipients.length - 1;
    const ccRecipients = otherRecipients && otherRecipients.length;
    if (additionalRecipients > 0 || ccRecipients > 0) {
      to += ` +${additionalRecipients + ccRecipients}`;
    }
  }

  const additionalInfo = {
    hasAttachments: (isEmail ? files.filter((file) => file.isAttachment) : files).length > 0,
    containsPii: !!piiSection,
  };

  return {
    id,
    direction: direction || '',
    from,
    to,
    date: date || '',
    notes: notes || '',
    title: title || '',
    docType,
    keywords,
    additionalInfo,
    hasNoFiles,
  };
};

export const documentViewModel = PropTypes.shape({
  id: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  docType: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  additionalInfo: PropTypes.shape({
    hasAttachments: PropTypes.bool.isRequired,
    containsPii: PropTypes.bool.isRequired,
  }),
});

export const RovingTabIndexVars = () => {
  const ref = useRef(null);
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(ref);
  useFocusEffect(focused, ref);
  return { ref, tabIndex, handleKeyDown, handleClick };
};
