import { mdiClockOutline, mdiStarOutline } from '@mdi/js';
import { Form, Formik, useField } from 'formik';
import { formatDate } from 'its-js-utility';
import { ContentRow } from 'its-react-ui';
import { array, func, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mutate } from 'swr';
import * as yup from 'yup';
import client from '../../apis/client';
import { UPDATE_SEARCH_RESULT_AFTER_UPDATE } from '../../containers/SearchResult/constants';
import { useDocumentTypes } from '../../hooks/documents';
import { useIsMobile } from '../../hooks/useIsMobile';
import { formatTime, toAction, isValidRole } from '../../lib';
import HttpStatus from '../../lib/HttpStatus';
import XHR_STATUS from '../../lib/xhrStatus';
import PiiSelector from '../CaseDocumentSearchPage/uploadFilesForm/PiiSelector';
import Button from '../Shared/Button';
import PageSpinner, { Spinner } from '../Shared/Spinner';
import { DocumentTypeSelector, KeywordsSelector, mapDocumentTypeToVM, NotesInput } from '../Shared/upsertDocumentHelpers';
import styles from './EditDocument.module.scss';

const defaultValidationShape = {
  documentType: yup.string().required('Select a document type'),
};
const defailtValidationSchema = yup.object().shape(defaultValidationShape);

const EditDocument = ({ close }) => {
  const document = useSelector((state) => state.searchResult.currentView.find((d) => d.id === state.searchResult.selectedDocumentsIds[0]));
  const userRole = useSelector((state) => state.user.roles.find((uRole) => isValidRole(uRole.name)));
  const hasValidApplicationRole = document.taxonomy?.applicationRoleName && isValidRole(document.taxonomy.applicationRoleName);
  const applicationRoleName = hasValidApplicationRole ? document.taxonomy.applicationRoleName : userRole.name;
  const { documentTypes, isLoading, isError } = useDocumentTypes(applicationRoleName);
  const [validationSchema, setValidationSchema] = useState(defailtValidationSchema);
  const { saveState, saveDocument, errorMessage } = useSaveDocument();
  const isSaving = saveState === XHR_STATUS.LOADING;

  const initialDocumentType = (isLoading ? [] : documentTypes).find((x) => x.name === document.taxonomy?.documentType);
  const initialDocumentTags = initialDocumentType
    ? initialDocumentType.tags.filter((x) => document.taxonomy?.documentTags?.includes(x.name)).map((x) => ({ ...x, value: x.name, selected: true }))
    : [];
  const initialValues = {
    documentType: initialDocumentType ? document.taxonomy?.documentType : null,
    notes: document.notes,
    keywords: initialDocumentTags,
    piiSection: document.piiSection,
  };

  useEffect(() => {
    if (saveState === XHR_STATUS.SUCCESS) {
      close();
    }
  }, [close, saveState]);

  const onSubmit = (values) => {
    const payload = {
      notes: values.notes,
      taxonomy: {
        applicationRoleName,
        documentType: values.documentType,
        documentTags: values.keywords.map((x) => x.value),
      },
      piiSection: values.piiSection,
      id: document.id,

      // These fields are not editable, but is required for the validation, as it is a put request.
      classification: document.classification,
      insuranceContexts: document.contexts,
      // TODO for // <LEAPDEV-1180> document.mailbox is not present, but may be needed for // <LEAPDEV-1180>,
      mailbox: 'ensure.this@is.userMailbox.com',
    };

    saveDocument(document, payload);
  };

  const updateValidationSchema = useCallback((showPii) => {
    setValidationSchema(
      showPii
        ? yup.object().shape({
            ...defaultValidationShape,
            piiSection: yup.string().required('Select a section'),
          })
        : defailtValidationSchema
    );
  }, []);

  if (isLoading) {
    return <PageSpinner />;
  }

  if (isError) {
    return <div className={styles.error}>Unable to load document types</div>;
  }

  return (
    <div className={styles.root}>
      {isSaving && <Spinner className={styles.loading} />}
      <fieldset disabled={isSaving}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          <Form>
            <FormFields
              documentTypes={documentTypes || []}
              classification={document.classification}
              date={document.date}
              updateValidationSchema={updateValidationSchema}
            />

            <div className={styles.actions}>
              {saveState === XHR_STATUS.ERROR && <span className={styles.error}>{errorMessage}</span>}
              <Button data-test-id="cX3mZZDUljJAHst4uoESZ" typeStyle="outline" onClick={close}>
                Cancel
              </Button>
              <Button type="submit">Update</Button>
            </div>
          </Form>
        </Formik>
      </fieldset>
    </div>
  );
};

EditDocument.propTypes = {
  close: func.isRequired,
};

export default EditDocument;

const FormFields = ({ documentTypes, classification, date, updateValidationSchema }) => {
  const isMobile = useIsMobile();
  const [documentTypeField] = useField('documentType');
  const documentType = documentTypes.find((d) => d.name === documentTypeField.value);

  const documentTypeVMs = useMemo(
    () =>
      documentTypes
        .filter((d) => !d.disabled)
        .map((d) => mapDocumentTypeToVM(d, documentTypeField.value))
        .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [documentTypeField.value, documentTypes]
  );

  return (
    <>
      <DocumentTypeSelector fixedSize={!isMobile} documentTypeVMs={documentTypeVMs} />

      <ContentRow icon={mdiStarOutline} title="Classification">
        <span>{classification}</span>
      </ContentRow>

      <ContentRow icon={mdiClockOutline} title="Time / Date">
        {date ? (
          <span>
            {formatTime(new Date(date))} / {formatDate(new Date(date))}
          </span>
        ) : (
          <span>Failed to find date</span>
        )}
      </ContentRow>

      <PiiSelector updateValidationSchema={updateValidationSchema} />

      <NotesInput />

      <KeywordsSelector tags={documentType ? documentType.tags : []} />
    </>
  );
};

FormFields.propTypes = {
  documentTypes: array.isRequired,
  classification: string.isRequired,
  date: string.isRequired,
  updateValidationSchema: func.isRequired,
};

const getErrorMessage = (resp) => {
  switch (resp.status) {
    case HttpStatus.FORBIDDEN:
      return 'You do not have an access to perform this action';
    default:
      return 'Unable to save document';
  }
};

export const useSaveDocument = () => {
  const [saveState, setSaveState] = useState(XHR_STATUS.INITIAL);
  const [errorMessage, setErrorMessage] = useState(XHR_STATUS.INITIAL);
  const dispatch = useDispatch();

  const saveDocument = async (document, payload) => {
    setSaveState(XHR_STATUS.LOADING);

    try {
      await client.put('document', payload);

      // Need to patch the updated document state together since the endpoint doesn't return anything
      const updatedDocument = {
        ...document,
        notes: payload.notes,
        taxonomy: payload.taxonomy,
        piiSection: payload.piiSection,
      };

      mutate(`metadata/${document.id}`);
      dispatch(toAction(UPDATE_SEARCH_RESULT_AFTER_UPDATE, updatedDocument));

      setSaveState(XHR_STATUS.SUCCESS);
    } catch (error) {
      setErrorMessage(getErrorMessage(error.response));
      setSaveState(XHR_STATUS.ERROR);
    }
  };

  return {
    saveState,
    saveDocument,
    errorMessage,
  };
};
