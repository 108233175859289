import { formatDate } from 'its-js-utility';
import { SortDateList } from '../components/Shared/SortList';

const isDateValid = (date) => date && !Number.isNaN(new Date(date).getTime());

export const getFormatedDate = (date) => {
  if (!isDateValid(date)) return '';

  return formatDate(new Date(date));
};

export const getFormatedTime = (date) => {
  if (!isDateValid(date)) return '';

  const d = new Date(date);
  const hours = `0${d.getHours()}`.slice(-2);
  const min = `0${d.getMinutes()}`.slice(-2);
  return `${hours}:${min}`;
};

export const getFormatedPeriod = ({ from, to }) => {
  const formatDateDefault = (date) => (date && formatDate(new Date(date))) || 'Not found';
  return `${formatDateDefault(from)} - ${formatDateDefault(to)}`;
};

export const addNamespace = (namespace) => (string) => `${namespace}.${string}`;

export const isEmailDocument = (item) => (item && item.$type === 'EmailDocumentResult') || false;

export const isEmailMetadata = (doc) => (doc.documentMetadata && doc.documentMetadata.$type === 'EmailMetadata') || false;

export const isMobile = (width) => width < 1024;

export const isEmptyString = (x) => !x || x.trim() === '';

const sortHistory = (history) => history.sort(SortDateList('date'));

export const getArchivedInfo = (history) => {
  const defaultInfo = { user: '', date: '' };
  if (!history || history.length === 0) return defaultInfo;

  const oldestVersion = sortHistory(history)[0];
  return { user: oldestVersion.user.name, date: oldestVersion.date };
};

export const sortByProp = (prop) => (a, b) => {
  const aProp = a[prop];
  const bProp = b[prop];

  const aType = typeof aProp;
  const bType = typeof bProp;

  if (aType === 'number' && bType === 'number') {
    return aProp - bProp;
  }

  if (aType === 'string' && bType === 'string') {
    return aProp.localeCompare(bProp);
  }

  throw Error(`Sorting not implemented for the given type of the props. a type: ${aType}, b type: ${bType} `);
};
