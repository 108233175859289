import { RemoteData } from 'its-js-utility';
import { filter, flatten, map, pipe, prop, uniqBy } from 'ramda';
import { createReducer } from '../../lib';
import XHR_STATUS from '../../lib/xhrStatus';
import { FETCH_INITIAL_CASE_DOCUMENTS } from '../CaseSearch';
import { RESET_SEARCH } from '../Search/constants';
import * as a from './constants';

const hasContext = (document) => document.contexts && document.contexts.length > 0;

const getContexts = pipe(
  filter(hasContext),
  map((x) => x.contexts),
  flatten,
  uniqBy(prop('id'))
);

const hasNoItems = (data) => data.length === 0;
const hasNoFiles = (el) => el?.taxonomy?.documentTags.includes('DocFlow');

const initialState = {
  remoteDataState: RemoteData.NotAsked,
  remoteNextPageDataState: RemoteData.NotAsked,
  remoteFilterChangedDataState: XHR_STATUS.INITIAL,
  currentView: [],
  hasMoreItems: true,
  selectedDocumentsIds: [],
  selectedContexts: [],
  hasNoItems: true,
};

const searchResultReducer = createReducer(initialState)({
  [a.FETCH_SEARCH_RESULT]: (state, action) => ({
    ...state,
    remoteDataState: RemoteData.Loading,
    remoteNextPageDataState: RemoteData.NotAsked,
    remoteFilterChangedDataState: XHR_STATUS.INITIAL,
    selectedDocumentsIds: [],
    selectedContexts: [],
    hasNoItems: true,
  }),
  [FETCH_INITIAL_CASE_DOCUMENTS]: (state) => ({
    ...state,
    currentView: [],
    selectedDocumentsIds: [],
    selectedContexts: [],
  }),
  [a.FETCH_SEARCH_RESULT_SUCCESS]: (state, { payload: searchResult }) => ({
    ...state,
    remoteDataState: RemoteData.Success(searchResult),
    currentView: uniqBy(prop('id'), searchResult.documents),
    hasMoreItems: searchResult.hasMoreItems,
    hasNoItems: hasNoItems(searchResult.documents),
  }),
  [a.FETCH_SEARCH_RESULT_FAILURE]: (state, action) => ({
    ...state,
    remoteDataState: RemoteData.Failure(action.payload),
  }),
  [a.FETCH_DOCUMENT_FILTER]: (state, _) => ({
    ...state,
    remoteFilterChangedDataState: XHR_STATUS.LOADING,
  }),
  [a.FETCH_DOCUMENT_FILTER_SUCCESS]: (state, { payload: searchResult }) => ({
    ...state,
    currentView: uniqBy(prop('id'), searchResult.documents),
    hasMoreItems: searchResult.hasMoreItems,
    hasNoItems: hasNoItems(searchResult.documents),
    remoteFilterChangedDataState: XHR_STATUS.SUCCESS,
  }),
  [a.FETCH_DOCUMENT_FILTER_FAILURE]: (state, _) => ({
    ...state,
    remoteFilterChangedDataState: XHR_STATUS.ERROR,
  }),
  [a.FETCH_NEXT_SEARCH_RESULT]: (state, action) => ({
    ...state,
    remoteNextPageDataState: RemoteData.Loading,
  }),
  [a.NEXT_SEARCH_RESULT_SUCCESS]: (state, action) => {
    const concatUniq = (oldDocuments, newDocuments) => uniqBy(prop('id'), [...oldDocuments, ...newDocuments]);
    const uniqueDocuments = concatUniq(state.currentView, action.payload.documents);
    return {
      ...state,
      remoteNextPageDataState: RemoteData.Success(action.payload),
      currentView: uniqueDocuments,
      hasMoreItems: action.payload.hasMoreItems,
      hasNoItems: hasNoItems(uniqueDocuments),
    };
  },
  [a.NEXT_SEARCH_RESULT_FAILURE]: (state, action) => ({
    ...state,
    remoteNextPageDataState: RemoteData.Failure(action.payload),
    hasMoreItems: false,
  }),
  [a.TOGGLE_DOCUMENT_SELECTION]: (state, { payload }) => {
    const selectedDocumentsIds = state.selectedDocumentsIds.includes(payload)
      ? state.selectedDocumentsIds.filter((id) => id !== payload)
      : state.selectedDocumentsIds.concat(payload);
    const selectedDocuments = state.currentView.filter((el) => !hasNoFiles(el)).filter((document) => selectedDocumentsIds.includes(document.id));
    const selectedContexts = getContexts(selectedDocuments);
    return {
      ...state,
      selectedDocumentsIds,
      selectedContexts,
    };
  },
  [a.SELECT_ALL_DOCUMENTS]: (state, action) => ({
    ...state,
    selectedDocumentsIds: state.currentView.filter((el) => !hasNoFiles(el)).map((document) => document.id),
    selectedContexts: getContexts(state.currentView),
  }),
  [a.UNSELECT_ALL_DOCUMENTS]: (state, action) => ({
    ...state,
    selectedDocumentsIds: [],
    selectedContexts: [],
  }),
  [a.UPDATE_SEARCH_RESULT_AFTER_UPDATE]: (state, { payload }) => ({
    ...state,
    currentView: state.currentView.map((item) => (item.id === payload.id ? payload : item)),
  }),
  [a.DELETE_SELECTED_DOCUMENTS]: (state) => {
    // Purge the document selection, the currentView will be refetched on deletion
    return {
      ...state,
      selectedDocumentsIds: [],
      selectedContexts: [],
    };
  },
  [RESET_SEARCH]: (_, __) => ({ ...initialState }),
});

export default searchResultReducer;
