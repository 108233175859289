import { mdiChevronDown } from '@mdi/js';
import cn from 'classnames';
import { onEnter } from 'its-js-utility';
import { Icon } from 'its-react-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { sortChanged } from '../../containers/Filters/actions';
import { KnownQueryParams } from '../../containers/queryParameters';
import { ICON_SIZE } from '../constVariables';
import s from './SortByMenu.module.scss';
import { defaultSort, sortOptions } from './sortOptions';

const SortByButton = ({ name, checked, onClick }) => (
  <div
    data-test-id="0NoWoC3Smf195tJ6b3imj"
    role="button"
    tabIndex={-1}
    onClick={() => onClick(name)}
    onKeyUp={() => onEnter(() => onClick(name))}
    className={cn(s.item, checked ? s.checked : null)}
  >
    <div className={s.circle} />
    {name}
  </div>
);

SortByButton.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const SortByMenu = ({ scrollToTop }) => {
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams({
    [KnownQueryParams.PAGE]: NumberParam,
    [KnownQueryParams.SORT_BY]: StringParam,
    [KnownQueryParams.SORT_DIRECTION]: StringParam,
  });

  const { [KnownQueryParams.SORT_BY]: sortBy, [KnownQueryParams.SORT_DIRECTION]: sortDireciton } = queryParams;

  useEffect(() => {
    // Set sort order if it is not set when the component initalizes for the first time
    if (!queryParams[KnownQueryParams.SORT_BY]) {
      setQueryParams(
        {
          [KnownQueryParams.SORT_BY]: defaultSort.sortBy,
          [KnownQueryParams.SORT_DIRECTION]: defaultSort.sortDirection,
        },
        'replaceIn'
      );
    }
  });

  const changeSortOrder = useCallback(
    (name) => {
      scrollToTop();

      const sortOption = sortOptions.find((option) => option.name === name);
      const isSame = sortOption.sortBy === sortBy && sortOption.sortDirection === sortDireciton;
      if (!isSame) {
        setQueryParams(
          {
            [KnownQueryParams.PAGE]: undefined,
            [KnownQueryParams.SORT_BY]: sortOption.sortBy,
            [KnownQueryParams.SORT_DIRECTION]: sortOption.sortDirection,
          },
          'replaceIn'
        );

        dispatch(sortChanged());
      }
    },
    [dispatch, scrollToTop, setQueryParams, sortBy, sortDireciton]
  );

  const selectedOption =
    sortBy && sortDireciton ? sortOptions.find((option) => option.sortBy === sortBy && option.sortDirection === sortDireciton) : defaultSort;
  return (
    <div className={s.container}>
      <div className={s.title}>
        SORT BY <span>{selectedOption && selectedOption.name}</span>
        <Icon path={mdiChevronDown} size={ICON_SIZE} />
      </div>
      <div className={s.tooltip}>
        {sortOptions.map((option) => {
          const selected = selectedOption.name === option.name;
          return (
            <SortByButton data-test-id="gVHhJMHkTfqwe5D-cmlYA" key={option.name} name={option.name} checked={selected} onClick={changeSortOrder} />
          );
        })}
      </div>
    </div>
  );
};

SortByMenu.propTypes = {
  scrollToTop: PropTypes.func.isRequired,
};

export default SortByMenu;
