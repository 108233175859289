import { mdiChevronDown, mdiChevronUp, mdiUnfoldMoreHorizontal } from '@mdi/js';
import MdiIcon from '@mdi/react';
import cn from 'classnames';
import { mapTaxonomyDisplayName } from 'its-js-utility';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBlockLayout, useRowSelect, useSortBy, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { List, AutoSizer } from 'react-virtualized';
import { useDetailsQueryParam } from '../../hooks/documents';
import useEventListener from '../../hooks/useEventListener';
import { getFormatedDate, getFormatedTime } from '../../lib/helpers';
import { Tag } from '../Shared';
import styles from './CaseDocumentTable.module.scss';
import {
  additionalInfoColumn,
  addSelectionColumn,
  dateColumn,
  directionColumn,
  docTypeColumn,
  documentViewModel,
  fromColumn,
  keywordsColumn,
  notesColumn,
  titleColumn,
  toColumn,
} from './helpers';

const createCaseDocumentTableHeader = () => [
  directionColumn,
  fromColumn,
  toColumn,
  dateColumn,
  notesColumn,
  titleColumn,
  docTypeColumn,
  keywordsColumn,
  additionalInfoColumn,
];

const CaseDocumentTable = ({
  documents,
  openDocumentId,
  selectedDocumentsIds,
  toggleDocumentSelection,
  selectedDocumentIds,
  setSelectedDocumentIds,
}) => {
  const limitedSpace = !!openDocumentId;
  const defaultColumn = useMemo(() => ({ width: 150 }), []);
  const tableData = useMemo(() => documents, [documents]);
  const tableHeaders = useMemo(() => createCaseDocumentTableHeader(), []);
  const getRowId = (rowDocumentData, _, __) => rowDocumentData.id;
  const tableInstance = useTable(
    {
      columns: tableHeaders,
      data: tableData,
      defaultColumn,
      getRowId,
      autoResetSortBy: false,
      initialState: {
        selectedRowIds: selectedDocumentIds,
      },
    },
    useSortBy,
    useRowSelect,
    useBlockLayout,
    (hooks) => {
      addSelectionColumn(hooks, toggleDocumentSelection);
    }
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  const { toggleQueryParam } = useDetailsQueryParam();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(null);

  useEffect(() => {
    if (selectedDocumentsIds.length === 0) {
      tableInstance.toggleAllRowsSelected(false);
      return;
    }

    if (selectedDocumentsIds.length === documents.length) {
      tableInstance.toggleAllRowsSelected(true);
    }
  }, [documents.length, selectedDocumentsIds, tableInstance]);

  useEffect(() => {
    const selectedIds = selectedDocumentsIds.reduce(
      (selectedRows, docId) => ({
        ...selectedRows,
        [docId]: true,
      }),
      {}
    );

    setSelectedDocumentIds(selectedIds);
  }, [selectedDocumentsIds, setSelectedDocumentIds]);

  const toggleDocumentDetails = useCallback(
    (documentId) => {
      const documentIndex = rows.findIndex((item) => item.id === documentId);
      setCurrentDocumentIndex(documentIndex);
      toggleQueryParam(documentId);
    },
    [toggleQueryParam, rows]
  );

  const handleNavigateDown = () => {
    if (currentDocumentIndex + 1 < documents.length) {
      const nextDocumentId = rows[currentDocumentIndex + 1].id;
      toggleDocumentDetails(nextDocumentId);
    }
  };

  const handleNavigateUp = () => {
    if (currentDocumentIndex > 0) {
      const prevDocumentId = rows[currentDocumentIndex - 1].id;
      toggleDocumentDetails(prevDocumentId);
    }
  };

  const onKeyDown = (event) => {
    event.preventDefault();
    if (event.key === 'ArrowUp') handleNavigateUp();
    if (event.key === 'ArrowDown') handleNavigateDown();
    if (event.key === 'Enter') toggleDocumentDetails(rows[currentDocumentIndex].id);
  };

  const elemRef = useRef();
  useEventListener(elemRef, 'keydown', onKeyDown);

  const RenderRow = useCallback(
    ({ index, style, key }) => {
      const row = rows[index];
      prepareRow(row);
      const documentId = row.id;
      const { id, date, from, to, title } = row.original;
      const formattedDateTime = `${getFormatedDate(date)} ${getFormatedTime(date)}`;
      const ariaLabel = `Open details for document from ${from}, to ${to}, titled ${title}, dated ${formattedDateTime}'`;
      const isOpen = id === openDocumentId;
      return (
        <div
          data-test-id="K6xsiMp25y9aoqXv7bHE2"
          key={key}
          onClick={() => toggleDocumentDetails(documentId)}
          aria-label={ariaLabel}
          {...row.getRowProps({ style })}
          className={cn(styles.tr, styles.document, { [styles.open]: isOpen })}
        >
          {row.cells.map((cell) => (
            <div
              {...cell.getCellProps({
                className: cn(cell.column.className, styles.td, {
                  [styles.limitedSpace]: limitedSpace,
                }),
              })}
            >
              {cell.render('Cell')}
            </div>
          ))}
        </div>
      );
    },
    [rows, prepareRow, openDocumentId, toggleDocumentDetails, limitedSpace]
  );
  return (
    <>
      <div className={styles.tableWrapper}>
        <div {...getTableProps({ className: styles.table })} ref={elemRef}>
          <div>
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps({
                  style: { width: '100%' },
                })}
                className={styles.tr}
              >
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps({
                      className: cn(column.classNameHeader, styles.th, {
                        [styles.limitedSpace]: limitedSpace,
                      }),
                      ...column.getSortByToggleProps(),
                    })}
                  >
                    {column.render('Header')}
                    {!column.disableSortBy && (
                      <span>
                        {column.isSorted ? (
                          <MdiIcon path={column.isSortedDesc ? mdiChevronDown : mdiChevronUp} size="12px" />
                        ) : (
                          <MdiIcon path={mdiUnfoldMoreHorizontal} size="12px" />
                        )}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps({ className: styles.body })}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  scrollToAlignment="auto"
                  scrollToIndex={currentDocumentIndex}
                  className={styles.documentList}
                  width={width}
                  height={height}
                  rowCount={rows.length}
                  rowHeight={65}
                  rowRenderer={RenderRow}
                  overscanRowCount={4}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
      <ReactTooltip
        place="bottom"
        className={styles.tooltip}
        id="keywords-tooltip"
        getContent={(dataTip) => {
          const keywords = (dataTip && dataTip.split(',')) || [];

          return keywords.length > 0
            ? keywords.map((keyword) => <Tag key={keyword} className={styles.tag} text={mapTaxonomyDisplayName(keyword)} type="stroke" />)
            : null;
        }}
      />
    </>
  );
};

CaseDocumentTable.propTypes = {
  documents: PropTypes.arrayOf(documentViewModel).isRequired,
  openDocumentId: PropTypes.string,
  selectedDocumentsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleDocumentSelection: PropTypes.func.isRequired,
  selectedDocumentIds: PropTypes.object,
  setSelectedDocumentIds: PropTypes.func,
};

export default CaseDocumentTable;
