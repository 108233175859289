import PropTypes from 'prop-types';
import { customPropTypes } from 'its-js-utility';
import { remoteDataShape } from './customProptypes';

const RemoteData = ({ type, notAsked, loading, failure, success }) =>
  type.cata({
    NotAsked: notAsked || (() => null),
    Loading: loading || (() => 'Loading...'),
    Failure: failure,
    Success: success,
  });

RemoteData.propTypes = {
  type: PropTypes.shape(remoteDataShape).isRequired,
  notAsked: customPropTypes.oneParamFunc,
  loading: customPropTypes.oneParamFunc,
  failure: customPropTypes.oneParamFunc.isRequired,
  success: customPropTypes.oneParamFunc.isRequired,
};

export default RemoteData;
