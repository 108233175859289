import { RemoteData } from 'its-js-utility';
import { getCover, searchClaimContexts, searchCoverContexts, searchReinsuranceContexts } from '../../apis/insuranceContext';
import { RoleCategoryEnum } from '../../lib';
import {
  GET_COVER_INFO,
  GET_COVER_INFO_SUCCESS,
  GET_COVER_INFO_ERROR,
  SET_CONTEXTS_IN_SEARCH,
  HIDE_SEARCH,
  CLOSE_MODAL,
  OPEN_MODAL,
  SHOW_SEARCH,
  SET_SELECTED_CONTEXTS,
  SET_SELECTED_CLAIM_CONTEXTS_IN_SEARCH,
  SET_SELECTED_CONTEXTS_GROUPS,
  UNSELECT_REINSURANCE_CONTEXT,
  SET_SELECTED_REINSURANCE_CONTEXTS_IN_SEARCH,
  SET_SELECTED_COVER_CONTEXTS_IN_SEARCH,
} from './constants';

export const getCoverInfo = (dispatch, contextId) => {
  dispatch({ type: GET_COVER_INFO, contextId });
  getCover(contextId)
    .then((context) => dispatch({ type: GET_COVER_INFO_SUCCESS, contextId, context }))
    .catch(() => dispatch({ type: GET_COVER_INFO_ERROR, contextId }));
};
export const searchContexts = (dispatch) => (searchString, searchInFinished, role) => {
  dispatch({ type: SET_CONTEXTS_IN_SEARCH, value: RemoteData.Loading });
  let search;
  switch (role) {
    case RoleCategoryEnum.UNDERWRITER:
      search = searchCoverContexts(searchString, role);
      break;

    case RoleCategoryEnum.CLAIMS_HANDLER:
      search = searchClaimContexts(searchString, searchInFinished, role);
      break;

    case RoleCategoryEnum.REINSURANCE:
      search = searchReinsuranceContexts(searchString);
      break;

    default:
      throw Error(`[${role}] not implemented`);
  }

  search
    .then((foundContexts) =>
      dispatch({
        type: SET_CONTEXTS_IN_SEARCH,
        value: RemoteData.Success(foundContexts),
      })
    )
    .catch((error) =>
      dispatch({
        type: SET_CONTEXTS_IN_SEARCH,
        value: RemoteData.Failure(error),
      })
    );
};
export const hideSearch = (dispatch) => () => dispatch({ type: HIDE_SEARCH });
export const contextSelected = (dispatch) => (context) => dispatch({ type: SET_SELECTED_CONTEXTS, context });

export const contextSelectedInSearch = (dispatch) => (context, selectedOptions) => {
  switch (context.$type) {
    case 'ReinsuranceContext':
      return dispatch({
        type: SET_SELECTED_REINSURANCE_CONTEXTS_IN_SEARCH,
        context,
        selectedOptions,
      });
    case 'ClaimContext':
      return dispatch({
        type: SET_SELECTED_CLAIM_CONTEXTS_IN_SEARCH,
        context,
      });
    case 'CoverContext':
      return dispatch({
        type: SET_SELECTED_COVER_CONTEXTS_IN_SEARCH,
        context,
        isSelected: selectedOptions,
      });
    default:
      throw Error(`Unsupported context type [${context.$type}]`);
  }
};

export const setSelectedContextsGroups = (dispatch) => (newSelectedContextList) =>
  dispatch({ type: SET_SELECTED_CONTEXTS_GROUPS, newSelectedContextList });
export const closeModal = (dispatch) => () => dispatch({ type: CLOSE_MODAL });
export const openModal = (dispatch) => (selectedContext) => dispatch({ type: OPEN_MODAL, selectedContext });
export const showSearch = (dispatch) => () => dispatch({ type: SHOW_SEARCH });
export const unselectReinsuranceContext = (dispatch) => (context) => dispatch({ type: UNSELECT_REINSURANCE_CONTEXT, context });
