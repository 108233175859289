import React from 'react';
import { mapTaxonomyDisplayName } from 'its-js-utility';
import { documentResultShape } from '../../../lib/customProptypes';
import { DateTime, Tag } from '../../Shared';
import { sortReinsuranceContexts } from '../../../lib/reinsuranceContext';
import { isEmailDocument } from '../../../lib/helpers';
import { fileData } from '../../SearchResult/itemBottomContent/helpers';
import style from './ModalDeleteDocument.module.scss';
import ContextTable from './ContextTable';
import { mapClaims, mapCovers, mapReinsurances } from './utils';

const DocumentType = ({ document }) => {
  const documentType = document.taxonomy && document.taxonomy.documentType;
  if (!documentType) return null;
  return <Tag text={mapTaxonomyDisplayName(document.taxonomy.documentType)} />;
};

DocumentType.propTypes = {
  document: documentResultShape,
};

const ContextSelector = ({ document }) => {
  const { contexts } = document;
  const claims = contexts.filter((x) => x.$type === 'ClaimContext');
  const claimsMap = claims.length > 0 ? mapClaims(claims) : null;

  const covers = contexts.filter((x) => x.$type === 'CoverContext');
  const coversMap = covers.length > 0 ? mapCovers(covers) : null;

  const reinsurances = contexts.filter((x) => x.$type === 'ReinsuranceContext').sort(sortReinsuranceContexts);
  const reinsurancesMap = reinsurances.length > 0 ? mapReinsurances(reinsurances) : null;

  const isMail = isEmailDocument(document);
  return (
    <div>
      <div className={style.documentInformation}>
        {isMail ? document.subject : fileData(document)}
        <DateTime showTime date={document.date} />
        <DocumentType document={document} />
      </div>
      <div>
        {!!claimsMap && <ContextTable documentId={document.id} headers={claimsMap.headers} rows={claimsMap.rows} availableContexts={claims} />}
        {!!coversMap && <ContextTable documentId={document.id} headers={coversMap.headers} rows={coversMap.rows} availableContexts={covers} />}
        {!!reinsurancesMap && (
          <ContextTable documentId={document.id} headers={reinsurancesMap.headers} rows={reinsurancesMap.rows} availableContexts={reinsurances} />
        )}
      </div>
    </div>
  );
};

ContextSelector.propTypes = {
  document: documentResultShape,
};

export default ContextSelector;
