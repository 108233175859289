import React from 'react';
import PropTypes from 'prop-types';
import { mapTaxonomyDisplayName } from 'its-js-utility';
import ReactTooltip from 'react-tooltip';
import styles from './tagTooltip/TagTooltip.module.scss';
import Tag, { tagType } from './Tag';

const TagTooltip = ({ type = tagType.stroke }) => (
  <ReactTooltip
    place="bottom"
    className={styles.reactTooltipStyle}
    id="keywords-tooltip"
    getContent={(dataTip) => {
      const keywords = (dataTip && dataTip.split(',')) || [];

      return keywords.length > 0
        ? keywords.map((keyword) => <Tag key={keyword} className={styles.tag} text={mapTaxonomyDisplayName(keyword)} type={type} />)
        : null;
    }}
  />
);

TagTooltip.propTypes = {
  type: PropTypes.oneOf(Object.values(tagType)),
};

export default TagTooltip;
