import React from 'react';
import PropTypes from 'prop-types';
import { mapTaxonomyDisplayName } from 'its-js-utility';
import Tag, { tagType } from './Tag';
import styles from './Tags.module.scss';

const { tag, tags } = styles;

const Tags = ({ documentTags = [] }) => (
  <div className={tags}>
    {documentTags.map((x) => (
      <Tag className={tag} type={tagType.stroke} key={x} text={mapTaxonomyDisplayName(x)} />
    ))}
  </div>
);

Tags.propTypes = {
  documentTags: PropTypes.arrayOf(PropTypes.string),
};

export default Tags;
