import React from 'react';
import PropTypes from 'prop-types';
import { onEnter } from 'its-js-utility';
import ItemContent from './ItemContent';
import { documentResultShape } from '../../lib/customProptypes';
import style from './SearchResult.module.scss';
import { Checkbox } from '../Shared';
import { RovingTabIndexVars } from '../CaseDocumentSearchPage/helpers';

const DesktopItem = ({
  document,
  hasNoFiles,
  isSelected,
  isOpened,
  toggleDocumentDetails,
  toggleDocumentSelection,
  handleNavigateUp,
  handleNavigateDown,
}) => {
  const { ref, tabIndex, handleKeyDown, handleClick } = RovingTabIndexVars();
  const onClick = (event) => {
    handleClick(event);
    toggleDocumentDetails(document.id);
  };
  const onKeyDown = (event) => {
    handleKeyDown(event);
    if (event.key === 'ArrowUp') handleNavigateUp(document.id);
    if (event.key === 'ArrowDown') handleNavigateDown(document.id);
  };
  return (
    <div
      data-test-id="kAcFoUWY6dstb4Pw936Zd"
      ref={ref}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={onClick}
      className={style.row}
      type="button"
      role="button"
      onKeyUp={onEnter(() => toggleDocumentDetails(document.id))}
    >
      <div className={hasNoFiles ? style.checkboxDisabled : style.checkbox}>
        <Checkbox
          data-test-id="UUF1ztTd2eBsdNjeawLRe"
          disabled={hasNoFiles}
          isSelected={isSelected}
          onClick={() => toggleDocumentSelection(document.id)}
          text="Disabled, the document has to be signed first."
        />
      </div>
      <div
        data-test-id="D6WooMiWG2yXuBL011p4N"
        className={`${style.box} ${isOpened ? style.boxSelected : ''}`}
        onClick={() => toggleDocumentDetails(document.id)}
      >
        <ItemContent item={document} isMobile={false} />
      </div>
    </div>
  );
};

DesktopItem.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  hasNoFiles: PropTypes.bool,
  document: documentResultShape.isRequired,
  toggleDocumentDetails: PropTypes.func.isRequired,
  toggleDocumentSelection: PropTypes.func.isRequired,
  handleNavigateDown: PropTypes.func.isRequired,
  handleNavigateUp: PropTypes.func.isRequired,
};

export default DesktopItem;
